import React, { useState, useEffect, useRef } from "react";
import "./sukukCom.css";
import { useHistory } from "react-router-dom";
import donateIcon from "../../../../../assets/donateIconForBanner.png";
import smsicon from "../../../../../assets/samsungpay.png";
import applePay from "../../../../../assets/applePay.png";
import cash from "../../../../../assets/mobile.png";
import cashbills from "../../../../../assets/donateByCash.png";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { startApplePay } from "../../../../../actions/handleApplePayment";
import DonationFinalPopUp from "../../../../checkOutPage/DonationFinalPopUp/DonationFinalPopUp";
import donateIconRed from "../../../../../assets/BagRed.png";
import { handleSamsungDirectPayment } from "../../../../../actions/handleSamsungPayment";
import Loading from "../../../../ui/loading";
import { async } from "@firebase/util";
import LoadingModal from "../../../../Auth/loginSignPage/loadingPopUp/loadingPopUp";
import sukuk from "../../../../../assets/sukukalkhair.png";
import SmsModal from "../../../../ui/smsModal/SmsModal";

const SukukCom = (props) => {
  const _isMounted = useRef(true);
  useEffect(() => {
    return () => {
        _isMounted.current = false;
    }
  }, []);
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [geustModal, setGuestModal] = useState(false);
  const [functionToSend, setFunctionToSend] = useState(() => {});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  let visitor = JSON.parse(localStorage.getItem("visitorDetails"));
  let userLogin = JSON.parse(localStorage.getItem("userLogin"));
  const [sukukAmount, setSukukAmount] = useState(parseInt(props.amount));
  const [smsModalShow, setSmsModalShow] = useState(false);

  const history = useHistory();
  const userFav = useSelector((state) => state.favorites.favorites);
  const [favoritesDetails, setFavoritesDetails] = useState(userFav);
  const [addingToFav, setAddingToFav] = useState(false);
  const [desiableMonthYer, setDisableMonthYer] = useState(props.recurring);

  useEffect(() => {
    setFavoritesDetails(userFav);
  }, [userFav]);

  const [inTheBagText, setInTheBagText] = useState("Add to Bag");
  const [animationClass, setAnimationClass] = useState("btn_with_text");

  const [btnToBeStyled, setBtnToBeStyled] = useState(`${props.donationID}_N`);

  const userBag = useSelector((state) => state.bag.bag);
  const [bagDetails, setBagDetails] = useState(userBag);
  const [token, setToken] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);

  useEffect(() => {
    setBagDetails(userBag);
  }, [userBag]);
  const [choosenToDonateSuk, setChoosenToDonateSuk] = useState({
    sukId: props.donationID,
    donationFreq: "N",
    amount: parseInt(props.amount),
    title: props.title,
    img: props.sukukImg,
  });
  useEffect(() => {
    setChoosenToDonateSuk({
      sukId: props.donationID,
      donationFreq: "N",
      amount: parseInt(props.amount),
      title: props.title,
      img: props.sukukImg,
    });
    setDisableMonthYer(props.recurring);
  }, [props.donationID]);
  const [liked, setLiked] = useState(false);
  const [inTheBag, setInTheBag] = useState(0);
  useEffect(() => {
    if (!userLogin) {
      if (
        props.currentProfile?.liked?.sukuks?.filter(
          (item) => item.sukId === props.donationID
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    } else {
      if (
        favoritesDetails?.sukuks?.filter(
          (item) => item.dontypeID === props.donationID
        ).length > 0
      ) {
        setLiked(true);
      } else {
        setLiked(false);
      }
    }
    if (!userLogin) {
      if (
        props.currentProfile?.cart?.sukuks?.filter(
          (item) =>
            item.sukId === props.donationID &&
            item.donationFreq === choosenToDonateSuk.donationFreq
        ).length > 0
      ) {
        setInTheBag(
          props.currentProfile?.cart?.sukuks?.filter(
            (item) =>
              item.sukId === props.donationID &&
              item.donationFreq === choosenToDonateSuk.donationFreq
          ).length
        );
      }
    } else {
      if (
        bagDetails?.sukuks
          ?.filter(
            (item) =>
              item.dontypeID === props.donationID &&
              item.recurring === choosenToDonateSuk.donationFreq &&
              parseInt(item.baseAmount) === parseInt(choosenToDonateSuk.amount)
          )
          .slice(0)
          .shift()?.quantity
      ) {
        setInTheBag(
          bagDetails?.sukuks
            ?.filter(
              (item) =>
                item.dontypeID === props.donationID &&
                item.recurring === choosenToDonateSuk.donationFreq &&
                parseInt(item.baseAmount) ===
                  parseInt(choosenToDonateSuk.amount)
            )
            .slice(0)
            .shift()?.quantity
        );
      }
    }
  }, [
    props.donationID,
    props.currentProfile,
    bagDetails,
    choosenToDonateSuk.amount,
    choosenToDonateSuk.donationFreq,
    userFav,
  ]);
  const handleLikeDisLike = async (str) => {
    setAddingToFav(true);
    if (liked) {
      await props.handleDisLike({
        ...str,
        recurring: desiableMonthYer ? "N" : "Y",
        amount: parseInt(props.amount),
      });
      if (_isMounted.current) {
        setAddingToFav(false);
      }
      
    } else {
      await props.handleLike({
        ...str,
        recurring: desiableMonthYer ? "N" : "Y",
        amount: parseInt(props.amount),
      });
      if (_isMounted.current) {
        setAddingToFav(false);
      }
      
    }
  };
  const handleAddToBag = (str) => {
    props.handleaddToBag(
      str,
      setAddingToCart,
      setAnimationClass,
      setInTheBagText
    );
  };
  const handleClick = (op) => {
    if (op === "-") {
      if (choosenToDonateSuk.amount > parseInt(props.amount)) {
        setChoosenToDonateSuk({
          ...choosenToDonateSuk,
          amount: choosenToDonateSuk.amount - parseInt(props.amount),
        });
      }
    } else {
      setChoosenToDonateSuk({
        ...choosenToDonateSuk,
        amount: choosenToDonateSuk.amount + parseInt(props.amount),
      });
    }
  };
  useEffect(() => {
    setBtnToBeStyled(`${props.donationID}_${choosenToDonateSuk.donationFreq}`);
    // if (desiableMonthYer) {
    //    if (choosenToDonateSuk.donationFreq === 'N') {
    //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = '#ee4236'
    //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'white'
    //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'black'
    //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'black'
    // } else if (choosenToDonateSuk.donationFreq === 'M') {
    //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = '#ee4236'
    //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'black'
    //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'white'
    //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'black'
    // } else {
    //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_two_${props.donationID}`).style.backgroundColor = 'unset'
    //     document.getElementById(`btn_three_${props.donationID}`).style.backgroundColor = '#ee4236'
    //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'black'
    //     document.getElementById(`btn_two_${props.donationID}`).style.color = 'black'
    //     document.getElementById(`btn_three_${props.donationID}`).style.color = 'white'
    // }
    // } else {
    //     document.getElementById(`btn_one_${props.donationID}`).style.backgroundColor = '#ee4236'
    //     document.getElementById(`btn_one_${props.donationID}`).style.color = 'white'
    // }
  }, [choosenToDonateSuk, desiableMonthYer]);
  const donateNow = () => {
    startApplePay(
      {
        amount: choosenToDonateSuk.amount,
        cart: [
          {
            dontype_id: choosenToDonateSuk.sukId,
            item_type: 0,
            amount: choosenToDonateSuk.amount,
            recuring: choosenToDonateSuk.donationFreq,
            cart_serial: null,
          },
        ],
      },
      dispatch,
      history
    );
  };
  const [width, setWidth] = useState(window.innerWidth);
  const startSamsungPay = async () => {
    setLoadingModalShow(true);
    await handleSamsungDirectPayment({
      amount: choosenToDonateSuk.amount,
      url: window.location.href,
      item: [
        {
          dontype_id: choosenToDonateSuk.sukId,
          recuring: choosenToDonateSuk.donationFreq,
          amount: choosenToDonateSuk.amount,
          item_type: 0,
        },
      ],
    });
    setLoadingModalShow(false);
  };
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const sendSMS = () => {
    setSmsModalShow(true);
    //window.location.href = "sms:2441?&body=" + " 10 درهم ";
  };
  const handleGuestPopUp = async (options) => {
    // if (userLogin || visitor) {
      options();
    // } else {
    //   setFunctionToSend(() => options);
    //   setGuestModal(true);
    // }
  };
  return (
    <div className="sukukCom" style={{ position: "relative" }}>
      <label
        className="title_of_sukuk"
        style={
          lng === "arab"
            ? {
                width: "100%",
                textAlign: "right",
                fontSize: "20px",
                fontWeight: "bold",
              }
            : {
                width: "100%",
                textAlign: "left",
                fontSize: "20px",
                fontWeight: "bold",
              }
        }
      >
        {props.title}
      </label>
      {/*addingToCart?
            <div style={{position:'absolute', left: '0', right: '0', top: '0', bottom: '0', width:'100%'}}>
                <Loading height={'100%'}/>
            </div>:null*/}
      <div
        className="sukuk_item"
        style={
          lng === "arab"
            ? { flexDirection: "row", position: "relative" }
            : { position: "relative" }
        }
      >
        <div
          className="img_sukuk_wraper"
          style={{ position: "relative", width: "100%" }}
        >
          <img
            src={props.sukukImg}
            alt={props.title}
            className="sukuk_item_img"
          />
          {addingToFav ? (
            <button
              disabled={addingToFav}
              style={
                lng === "arab"
                  ? { left: "unset", right: "16px" }
                  : { left: "16px", right: "unset" }
              }
              className="like_btn_for_sukuk"
            >
              <div className="containerAnime">
                <div className="ballFav"></div>
                <div className="ballFav"></div>
              </div>
            </button>
          ) : (
            <React.Fragment>
              {lng === "arab" ? (
                <button
                  style={{ left: "unset", right: "16px" }}
                  onClick={() => handleLikeDisLike(choosenToDonateSuk)}
                  className="like_btn_for_sukuk"
                >
                  {liked ? (
                    <i
                      style={{ color: "#ee4236" }}
                      className="fas fa-heart"
                    ></i>
                  ) : (
                    <i style={{ color: "gray" }} className="far fa-heart"></i>
                  )}
                </button>
              ) : (
                <button
                  style={{ left: "16px", right: "unset" }}
                  onClick={() => handleLikeDisLike(choosenToDonateSuk)}
                  className="like_btn_for_sukuk"
                >
                  {liked ? (
                    <i
                      style={{ color: "#ee4236" }}
                      className="fas fa-heart"
                    ></i>
                  ) : (
                    <i style={{ color: "gray" }} className="far fa-heart"></i>
                  )}
                </button>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="sukuk_item_board">
          <div
            className="sukuk_item_board_time"
            style={lng === "arab" ? { flexDirection: "row-reverse" } : null}
          >
            <button
              style={
                btnToBeStyled === `${props.donationID}_N`
                  ? { backgroundColor: "#ee4236", color: "white" }
                  : null
              }
              disabled={addingToCart}
              id={`btn_one_${props.donationID}`}
              onClick={() =>
                setChoosenToDonateSuk({
                  ...choosenToDonateSuk,
                  donationFreq: "N",
                })
              }
              className="btn btn-block btn-light btn-frequ btn-sukuk btnFontSml"
            >
              {t("One Time")}
            </button>
            {!desiableMonthYer ? (
              <button
                style={
                  btnToBeStyled === `${props.donationID}_M`
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : null
                }
                disabled={addingToCart}
                id={`btn_two_${props.donationID}`}
                onClick={() =>
                  setChoosenToDonateSuk({
                    ...choosenToDonateSuk,
                    donationFreq: "M",
                  })
                }
                className="btn btn-block btn-light btn-frequ btn-sukuk"
              >
                {t("Monthly")}
              </button>
            ) : null}
            {!desiableMonthYer ? (
              <button
                style={
                  btnToBeStyled === `${props.donationID}_D`
                    ? { backgroundColor: "#ee4236", color: "white" }
                    : null
                }
                disabled={addingToCart}
                id={`btn_three_${props.donationID}`}
                onClick={() =>
                  setChoosenToDonateSuk({
                    ...choosenToDonateSuk,
                    donationFreq: "D",
                  })
                }
                className="btn btn-block btn-light btn-frequ btn-sukuk"
              >
                {t("Daily")}
              </button>
            ) : null}
          </div>
          <div
            className="sukuk_item_board_payment"
            style={
              lng === "arab"
                ? { flexDirection: "row-reverse" }
                : { minHeight: "70px" }
            }
          >
            {inTheBagText === "Added" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={"btn_with_text animate__animated animate__bounceIn"}
              >
                <button disabled={addingToCart} className="btn_for_sukuk">
                  <label style={{ fontSize: "10px", color: "#ee4236" }}>
                    {t("You now have ") + inTheBag + t(" in your Bag")}
                  </label>
                </button>
              </div>
            ) : (
              <div className={animationClass}>
                {addingToCart ? (
                  <Loading height={"70px"} small={"40px"} animate={true} />
                ) : (
                  <React.Fragment>
                    <button
                      disabled={addingToCart}
                      onClick={() => handleAddToBag(choosenToDonateSuk)}
                      className="btn_for_sukuk"
                    >
                      <img
                        src={donateIcon}
                        alt="donate_btn_icon_banner"
                        className="sukuk_btn_img_general"
                      />
                    </button>
                    <label className="btn_label_for_sukul">
                      {t(inTheBagText)}
                    </label>
                  </React.Fragment>
                )}
              </div>
            )}
            <div className="btn_with_text">
              {choosenToDonateSuk.donationFreq !== "N" ? (
                <button disabled className="btn_for_sukuk">
                  <img
                    src={window.ApplePaySession ? applePay : smsicon}
                    style={{ opacity: "40%" }}
                    alt="donate_btn_icon_banner"
                    className={
                      window.ApplePaySession
                        ? "apple_pay_icon"
                        : "sukuk_btn_img_general"
                    }
                  />
                </button>
              ) : (
                <button
                  className="btn_for_sukuk"
                  onClick={() =>
                    handleGuestPopUp(
                      window.ApplePaySession ? donateNow : startSamsungPay
                    )
                  }
                >
                  <img
                    src={window.ApplePaySession ? applePay : smsicon}
                    alt="donate_btn_icon_banner"
                    className={
                      window.ApplePaySession
                        ? "apple_pay_icon"
                        : "sukuk_btn_img_general"
                    }
                  />
                </button>
              )}
              <label className="btn_label_for_sukul">
                {window.ApplePaySession ? t("Apple Pay") : t("Samsung Pay")}
              </label>
            </div>
            {width >= 900 ? null : (
              <div className="btn_with_text">
                <button onClick={() => sendSMS()} className="btn_for_sukuk">
                  <img
                    src={cash}
                    alt="donate_btn_icon_banner"
                    className="sukuk_btn_img_sms"
                  />
                </button>
                <label className="btn_label_for_sukul">{t("Donate SMS")}</label>
              </div>
            )}
          </div>
          <div className="sukuk_inc_Dec">
            <button
              className="sukukdec btn btn-light"
              onClick={() => {
                if (choosenToDonateSuk.amount > 100) {
                  handleClick("-");
                }
              }}
            >
              -
            </button>
            <label
              className="inc_dec_label_sukuk"
              style={{ fontSize: "15px", textAlign: "center" }}
            >
              {t("AED")}{" "}
              <span
                className="payment_numbers"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontStyle: "none",
                  textAlign: "center",
                }}
              >
                {choosenToDonateSuk.amount}
              </span>
            </label>
            <button
              className="sukukinc btn btn-light"
              onClick={() => handleClick("+")}
            >
              +
            </button>
          </div>
          <div className="sukuk_inc_Dec noWidth">
            <img
              src={sukuk}
              alt="donate_btn_icon_banner"
              className="sukuk-img"
            />
            <div className="btn_with_text">
              <button
                onClick={() =>
                  history.push(`/checkout`, {
                    from: "donations",
                    elements: [
                      {
                        title: choosenToDonateSuk.title,
                        price: choosenToDonateSuk.amount,
                      },
                    ],
                    totalAmount: choosenToDonateSuk.amount,
                    details: choosenToDonateSuk,
                  })
                }
                className="btn_for_sukuk"
              >
                <img
                  src={cashbills}
                  alt="donate_btn_icon_banner"
                  className="sukuk_btn_img_apple"
                />
              </button>
              <label className="btn_label_for_sukul">{t("Donate Now")}</label>
            </div>
          </div>
        </div>
      </div>
      <DonationFinalPopUp
        show={geustModal}
        paymentfunction={functionToSend}
        onHide={() => setGuestModal(false)}
        dialogClassName="modal-60w"
      />
      <SmsModal
        show={smsModalShow}
        donationid={props.donationID}
        onHide={() => setSmsModalShow(false)}
      />
      <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  );
};
export default SukukCom;
